import React, { useEffect, useState } from 'react';

export const useLoader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading;
};
